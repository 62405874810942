import * as esbuild from 'esbuild-wasm';

export const unpkgPathPlugin = () => {
  return {
    name: 'unpkg-path-plugin',
    setup(build: esbuild.PluginBuild) {
      // onReslove will be called whenever esbuild to figure out a path for
      // a particular module

      // 1- find file with name of index.js
      // handle root entry file index.js
      build.onResolve({filter:/(^index\.js$)/},()=>{
        return { path: 'index.js', namespace: 'a' };
      })
      // 2-find file with name of include ./ or ../ 
      // handle relative path in module
      build.onResolve({filter:/^\.+\//},(args: any) =>{
        return{
          namespace:'a',
          path:new URL(args.path,'https://unpkg.com'+args.resolveDir+'/').href
        }
      })
      // 3-find root packages
      // handle main file of a module
     build.onResolve({ filter: /.*/ }, (args: any) => {
       return { path: `https://unpkg.com/${args.path}`, namespace: 'a' };    
      });
      // 1 and 2 ,3 combined
        // build.onResolve({ filter: /.*/ }, async (args: any) => {
        // console.log('onResole', args);
        // if (args.path === 'index.js') {
        //   return { path: args.path, namespace: 'a' };
        // }
        // if(args.path.includes('./')||args.path.includes('../')){
        //   return{
        //     namespace:'a',
        //     path:new URL(args.path,'https://unpkg.com'+args.resolveDir+'/').href
        //   }
        // }
        // return { path: `https://unpkg.com/${args.path}`, namespace: 'a' };
     
      // });
     
    },
  };
};