import React from 'react'
import CodeCell from '../../components/code-cell'
const startInput=
 ` 
  import React from 'react';
  import ReactDOM from "react-dom";
  const App=()=> {
    return (
      <div>
        <h1>Hello World!</h1>
        <p>Start editing to see some magic happen :)</p>
      </div>
    );
  }
  ReactDOM.render(<App />, document.getElementById("root"));
`
const ReactJs:React.FC=()=> {
  return (
  <div>
    <CodeCell startInput={startInput}/>
  </div>
  )
}

export default ReactJs