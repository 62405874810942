
import { useState,useEffect } from 'react'
import CodeEditor from './code-editor';
import Preview from './preview';
import bundle from '../bundler';
import Resizable from './resizable';

interface Props {
  startInput:string;
}

const CodeCell:React.FC<Props> = ({startInput}) => {
  const [input,setInput]=useState('');
  const [code,setCode]=useState('');
  const [err,setErr]=useState('');
  useEffect(()=>{
    setInput(startInput);
  },[])
  useEffect(()=>{   
   const timer = setTimeout(async()=>{
      const output=await bundle(input);
        setCode(output.code);
        setErr(output.err);
        },1000)
    return ()=>{
      clearTimeout(timer);
    }
  },[input])

  return (
    <>
    <Resizable direction='vertical'>
      <div style={{height:'100%',display:'flex',flexDirection:'row'}}
          >
        {/* <div> */}
        <Resizable direction='horizontal'>
           <CodeEditor initialValue={input} onChange={(value)=>setInput(value)}
            />
        </Resizable>
            {/* <button className='button is-success' onClick={onClick}>run</button> */}
        {/* </div> */}
         
          <Preview code={code} err={err}/>
      </div>
    </Resizable>

  
  {/* <Resizable direction='vertical'>
  <div className='columns is-1'>
    <div className='column is-6'>
    <CodeEditor 
       initialValue={input} 
       onChange={(value)=>setInput(value)}
    />          
      <div>
        <button className='button is-success' onClick={onClick}>run</button>
      </div>
    </div>
    
      <div id="editor" className='column is-6 '>
        <Preview code={code}/>
      </div>
  </div> 
     </Resizable> */}
 
  </>
  )
}
export default CodeCell;