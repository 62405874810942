import React from 'react'
import reactLogo from '../../assets/images/icons/react.svg'
import jsLogo from '../../assets/images/icons/js.svg';
import mdLogo from '../../assets/images/icons/md.svg';
import { Link } from "react-router-dom";

const Home:React.FC=()=> {
  return (
   <React.Fragment>
     <style>
        {`
        body{
          background-attachment: fixed;
          background-image: linear-gradient(to right bottom,#2196f3,#3f51b5 52%,#365a83);
          color: #fff;
          max-width: 100%;
          min-height: 100vh;
          overflow-x: hidden; 
        }
        `}
     </style>

     <header className='banner container is-max-desktop'>
       <h1>
         The fastest, most secure<br />
         dev environment<br />
         <span> on the planet.</span>
       </h1>
       <p>
         Create, edit & deploy fullstack apps with
         faster package installations & greater security
         than even local environments.
       </p>
     </header>
     <section className='container is-max-desktop tab-view'>
        <h5>FRONT-END FRAMEWORKS & LIBRARIES</h5>
        <ul className="tabs">
        <li>
            <Link to="/js">
              <img src={jsLogo} alt="js" width={`40`} height={`40`} />
            
              <span className='lang'>
                <span>Vanilla JavaScript</span>
                <span>JavaScript</span>
              </span>
            </Link>
            </li>
          <li>
            <Link to="/react">
              <img src={reactLogo} alt="React" width={`40`} height={`40`} />
            
              <span className='lang'>
                <span>React</span>
                <span>JavaScript</span>
              </span>
            </Link>
            </li>
          <li>
            <Link to="/react-api">
              <img src={reactLogo} alt="React" width={`40`} height={`40`} />

              <span className='lang'>
                <span>React API</span>
                <span>JavaScript</span>
              </span>
            </Link>
            </li>
          <li>
            <Link to="/md">
              <img src={mdLogo} alt="markdown" width={`40`} height={`40`} />
            
              <span className='lang'>
                <span>Markdown</span>
                <span>Text Editor</span>
              </span>
            </Link>
            </li>
            
        </ul>
     </section>    
   </React.Fragment>
  )
}

export default Home