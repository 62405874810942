import 'bulmaswatch/superhero/bulmaswatch.min.css';
import ReactDOM from 'react-dom';

import Routing from './routes';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import Layout from './components/layouts/Layout';
const App = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routing/>
       </Layout>
      </BrowserRouter>
  )
}
ReactDOM.render(<App />, document.getElementById('root'))