import React from 'react'
import CodeCell from '../../components/code-cell'
const startInput=
 ` 
 import 'bulmaswatch/superhero/bulmaswatch.min.css';
 import React from 'react';
 import ReactDOM from 'react-dom';
 import axios from 'axios';
 const Card = ({ item }) => {
 
   const { title, body } = item;
   return (
     <div className="card column is-one-quarter">
       <div className="card-image">
         <figure className="image is-4by3">
           <img
             src="https://bulma.io/images/placeholders/1280x960.png"
             alt="Placeholder image"
           />
         </figure>
       </div>
       <div className="card-content">
         <div className="media">
           <div className="media-left">
             <figure className="image is-48x48">
               <img
                 src="https://bulma.io/images/placeholders/96x96.png"
                 alt="Placeholder image"
               />
             </figure>
           </div>
           <div className="media-content">
             <p className="title is-4">{title}</p>
             <p className="subtitle is-6">@{title}</p>
           </div>
         </div>
         <div className="content">
           {body}. <a>@bulmaio</a>.<a href="#">#css</a>{' '}
           <a href="#">#responsive</a>
           <br />
           <time dateTime="2016-1-1">11:09 PM - 1 Jan 2016</time>
         </div>
       </div>
     </div>
   );
 };
 const CardList = () => {
   const [data, setData] = React.useState([]);
   const [loading, setLoading] = React.useState(false);
 
   React.useEffect(() => {
     setLoading(true);
     fetch('https://jsonplaceholder.typicode.com/posts')
       .then((response) => response.json())
       .then((json) => {
         console.log('json', json);
         setData(json);
       })
       .then(() => setLoading(false))
       .catch((err) => console.log(err));
   }, []);
   
   return (
     <div className="columns is-variable is-1-mobile is-0-tablet is-3-desktop is-8-widescreen is-2-fullhd">
       {loading ? (
         <p>loading</p>
       ) : (
         data?.map((item) => <Card key={item.id} item={item} />)
       )}
     </div>
   );
 };
 const App = () => {
   return (
     <div>
       <CardList />
     </div>
   );
 };
 ReactDOM.render(<App />, document.getElementById('root'));
`
const ReactJsAPI:React.FC=()=> {
  return (
  <div>
    <CodeCell startInput={startInput}/>
  </div>
  )
}

export default ReactJsAPI