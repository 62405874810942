import React from 'react'
import TextEditor from '../../components/text-editor';

const startInput=`
  # Header
  ## Sub Header
  ### Sub Sub Header
  - List
  - List
  - List
`;
const Md:React.FC=()=> {
  return (
    <div>
    <TextEditor startInput={startInput}/>
  </div>
  )
}

export default Md