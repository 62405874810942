import React from "react";
import MDEditor from "@uiw/react-md-editor";
import './text-editor.css';
interface Props {
  startInput:string;
}
const TextEditor: React.FC<Props>=({startInput})=>{
  const [editing,setEditing]=React.useState(true);
  const [value,setValue]=React.useState(startInput||'');
  React.useEffect(()=>{
      setValue(startInput);
  },[])
  const ref=React.useRef<HTMLDivElement | null>(null); 
  React.useEffect(()=>{
     const listener=(event:MouseEvent)=>{
       console.log(event.target);
       if(ref.current&&event.target&&ref.current.contains(event.target as Node)){
         return;
       }
       setEditing(false);
     };
     document.addEventListener("click",listener,{capture:true});
      return ()=>{
        document.removeEventListener("click",listener,{capture:true});
      }
  },[]);
  if(editing){

    return(
      <div className="text-editor" ref={ref}>
        <MDEditor value={value} onChange={v=>setValue(v||'')}/>
        <div className="w-100 text-center">
          <button className="button is-success  mt-4" onClick={()=>setEditing(false)}>save</button>
        </div>
        </div>
    )
  }
  return (
    <div className="text-editor  card" onClick={()=>setEditing(true)}>
      <div className="card-content">
      <MDEditor.Markdown source={value}/>
      </div>
    </div>
  )
}

export default TextEditor;