import React from 'react'
import { Navigate, Route, Routes} from 'react-router-dom';
import Home from './pages/home';
import Js from './pages/js';
import Md from './pages/md';
import ReactJs from './pages/react';

import ReactJsAPI from './pages/react-api';

const Routing:React.FC=()=> {
  return (

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/react" element={<ReactJs />} />
      <Route path="/react-api" element={<ReactJsAPI />} />
      <Route path="/js" element={<Js />} />
      <Route path="/md" element={<Md />} />
    </Routes>  
  
  )
}

export default Routing