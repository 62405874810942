import React, {useState, useEffect} from 'react'
import { NavLink,Link } from 'react-router-dom';
import './index.css'

const Header:React.FC=()=> {
  const [isExpanded, setIsExpanded] = useState(false);
  function toggleMobileMenu() {
    // menu.classList.toggle('open');
    setIsExpanded(!isExpanded);
}
  return (
    <header id="navbar">
    <div className='container is-max-desktop' id="content">
    <div id="brand"><a href="/">CodexJBook</a></div>
    <nav>
      <ul>
      <li><NavLink to="/js">js</NavLink></li>
        <li><NavLink to="/react">react</NavLink></li>
        <li><NavLink to="/react-api">react api</NavLink></li>
        <li><NavLink to="/md">md</NavLink></li>
        {/* <li id="login"><a href="/login">Login</a></li>
        <li id="signup"><a href="/signup">Signup</a></li> */}
      </ul>
    </nav>
    <div id="hamburger-icon" onClick={()=>toggleMobileMenu()}
        className={isExpanded?'open':''}
        >
      <div className="bar1" />
      <div className="bar2" />
      <div className="bar3" />
      <ul className="mobile-menu">
        <li><NavLink to="/js">js</NavLink></li>
        <li><NavLink to="/react">react</NavLink></li>
        <li><NavLink to="/react-api">react api</NavLink></li>
        <li><NavLink to="/md">md</NavLink></li>
        {/* <li id="login"><a href="/login">Login</a></li>
        <li id="signup"><a href="/signup">Signup</a></li> */}
      </ul>
    </div>
    </div>
  </header>
    );
}

export default Header