import React from 'react'
import CodeCell from '../../components/code-cell';
const html=`
   <div>
        <h1>Hello World!</h1>
        <p>Start editing to see some magic happen :)</p>
      </div> 
  `;
const startInput=`
const root=document.getElementById('root');

root.innerHTML+=\`${html}\`;
`;
const Js:React.FC=()=> {
  return (
    <div>
    <CodeCell startInput={startInput}/>
  </div>
  )
}

export default Js